import {request} from '@/utils/request'

// 预警名单列表
export function warningList (params) {
  return request({
      url: '/orgWarning2/list',
      method: 'get',
      params
  })
}

// 观察记录详情
export function viewDetail (params) {
  return request({
      url: '/orgStudentView/detail',
      method: 'get',
      params
  })
}

// 危机名单列表
export function orgRiskList (params) {
  return request({
      url: '/orgRisk/list',
      method: 'get',
      params
  })
}

// 上报局端
export function reportUp (params) {
  return request({
      url: '/orgWarning2/reportUp',
      method: 'get',
      params
  })
}

// 危机详情
export function riskDetail (params) {
  return request({
      url: '/orgRisk/detail',
      method: 'get',
      params
  })
}
